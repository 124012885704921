import { useQuery } from "@apollo/client";
import { Card, CardContent, Dialog, DialogTitle, IconButton, ListItem, ListItemText } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert, Loader } from "../../components/Elements/elements";
import NotificationsSticky from "../../components/Notification/NotificationsSticky";
import { Status } from "../../components/Status/Status";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { UserContext } from "../../utils/UserContext";
import { isKelep } from "../../constants";

export const HomePage = () => {
  let title = "";
  const { user } = useContext(UserContext);
  const { state } = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState(state?.message);
  const [report, setReport] = useState(false);
  const [open, setOpen] = useState(false);
  const [reportAlert, setReportAlert] = useState(false);
  const [contract, setContract] = useState(false);
  // const [showContract, setShowContract] = useState([]);

  let showContract;

  const { data } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "user_tenders",
      displayId: "rest_export_2",
    },
    fetchPolicy: "network-only",
  });

  const { data: userData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "user_tenders",
      displayId: "rest_export_3",
    },
    fetchPolicy: "network-only",
  });

  const { data: contractData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "szerzodesek",
      displayId: "rest_export_1",
    },
    fetchPolicy: "network-only",
  });

  const getActiveContracts = dataContract => {
    let arr = [];

    dataContract.map((item, idx) => {
      const stateValue = item.field_contract_state[0]?.value;

      if (stateValue === "open" || stateValue === "draft" || stateValue === "needs_update" || stateValue === "_none") {
        arr.push(item);
      }
    });

    return arr;
  };

  const close = () => {
    setMessage();
    history.replace();
  };
  if (userData) {
    let data = JSON.parse(userData?.view?.content);
    let now = new Date();
    data = data.sort((a, b) => new Date(a.field_monthly_report_end_date) - new Date(b.field_monthly_report_end_date));
    if (data.length) {
      title = data[0].title;
      const endDate = new Date(data?.[0]?.field_monthly_report_end_date);
      const startDate = new Date(data?.[0]?.field_monthly_report_start_date);

      let endDateString =
        endDate.toISOString().replaceAll("-", ".").split("T")[0] +
        ".-án/én, " +
        endDate.toLocaleTimeString("hu", {
          hour: "2-digit",
          minute: "2-digit",
        });
      if (now > startDate && now < endDate && !report) {
        setReportAlert(new Date(endDate.setHours(-72)) < now);
        setReport(endDateString);
      }
    }
  }
  if (contractData) {
    let data = JSON.parse(contractData?.view?.content);
    // let contractState = data[0]?.field_contract_state[0]?.value;
    if (data != "_none" || data != "locked" || data != "signing") {
      let countContract = true;
      if (data.length != 0 && !contract) {
        setContract(countContract);
      }
    }

    showContract = getActiveContracts(data);
  }

  useEffect(() => {
    // const scriptTag = document.createElement("script");
    const script = document.createElement("script");

    // scriptTag.src =
    //   "https://static.mobilemonkey.com/js/business_0088e34a-8b48-4b4b-a273-ca032437e4e5-11266902.js";
    // scriptTag.async = true;

    script.text = `
      window.mmDataLayer = window.mmDataLayer || [];
      function mmData(o) { mmDataLayer.push(o); }`;

    document.body.appendChild(script);
    // document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(script);
      // document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div className="homepage">
      {message && <Alert text={message} color="success" close={close} />}
      <h2 className="breki-title">Üdvözlünk, {user.firstName}!</h2>
      <div className="sticky-notifications">
        <NotificationsSticky />
      </div>
      {data ? (
        data.view.content !== '[]' && (
          <h3 className="breki-title">Aktuális teendők</h3>
        )
      ) : (
        <div>
          <Loader loading={true} />
        </div>
      )}

      <div className="tender-status-wrapper">
        {data
          ? JSON.parse(data.view.content).map((row, idx) => {
              // Támogatott (supported) pályázatok.
              if (row.field_tender_status === "4") {
                return contractData
                  ? JSON.parse(contractData.view.content).map((row, idx) => {
                      const tenderTitle = row.title[0]?.value;
                      if (row.field_contract_state[0]?.value == "draft") {
                        return (
                          <div className="contract-status-info">
                            <h3>A(z) {tenderTitle} pályázat szerződéskötésével kapcsolatos teendők</h3>
                            <br></br>
                            <p>
                              Kérlek, add meg a szerződékötéshez szükséges adataidat!
                              <Link
                                className="card-link"
                                to={{
                                  pathname: "/szerkesztes",
                                  state: {
                                    id: row.nid[0].value,
                                  },
                                }}
                              >
                                Tovább az adatlapra.
                              </Link>
                            </p>
                          </div>
                        );
                      } else if (row.field_contract_state[0]?.value == "open") {
                        return (
                          <div className="contract-status-info">
                            <h3>A(z) {tenderTitle} pályázat szerződéskötésével kapcsolatos teendők</h3>
                            <br></br>
                            <p>
                              Kérlek, add meg a szerződéskötéshez szükséges hiányzó adataidat!
                              <Link
                                className="card-link"
                                to={{
                                  pathname: "/szerkesztes",
                                  state: {
                                    id: row.nid[0].value,
                                  },
                                }}
                              >
                                Tovább az adatlapra.
                              </Link>
                            </p>
                          </div>
                        );
                      } else if (row.field_contract_state[0]?.value == "_none") {
                        return (
                          <div className="contract-status-info">
                            <h3>A(z) {tenderTitle} pályázat szerződéskötésével kapcsolatos teendők</h3>
                            <br></br>
                            <p>
                              Kérlek, add meg a szerződékötéshez szükséges adataidat!
                              <Link
                                className="card-link"
                                to={{
                                  pathname: "/szerkesztes",
                                  state: {
                                    id: row.nid[0].value,
                                  },
                                }}
                              >
                                Tovább az adatlapra.
                              </Link>
                            </p>
                          </div>
                        );
                      } else if (row.field_contract_state[0]?.value == "signing") {
                        return (
                          <div className="contract-status-info">
                            <h3>A(z) {tenderTitle} pályázat szerződéskötésével kapcsolatos teendők</h3>
                            <br></br>
                            <p>
                              Kérlek, add meg a szerződéskötéshez szükséges hiányzó adataidat!
                              <Link
                                className="card-link"
                                to={{
                                  pathname: "/szerkesztes",
                                  state: {
                                    id: row.nid[0].value,
                                  },
                                }}
                              >
                                Tovább az adatlapra.
                              </Link>
                            </p>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null;
              } else {
                return <Status key={idx} data={row} item_index={idx} />;
              }
            })
          : null}
      </div>
      <div className="homepage-items">
        <Link to="palyazatok" className="card-link">
          <Card className="card center">
            <CardContent>
              <span className="card-image homepalyazatok"></span>
              <h2 className="card-underline">Pályázatok</h2>
              <div className="card-text">Aktuális pályázatok és azokra való jelentkezés</div>
            </CardContent>
          </Card>
        </Link>
        {report && !isKelep && (
          <>
            <Card className="card center" style={{ cursor: "pointer" }} onClick={() => setOpen(el => !el)}>
              <CardContent style={{ paddingBottom: "0" }}>
                <span className="card-image jelen"></span>
                <h2 className="card-underline">Jelentés</h2>
                <div className="card-text">
                  {userData &&
                    JSON.parse(userData.view.content).map(el => {
                      let bundle = JSON.parse(el.tender_info).bundle;
                      return (
                        <Link
                          className="card-link"
                          to={{
                            pathname: "/jelentes",
                            state: {
                              id: el.nid,
                              bundle,
                            },
                          }}
                        >
                          <ListItem button style={{ padding: "5px 0px", borderRadius: "8px" }}>
                            <ListItemText
                              primary={
                                <div
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}
                                  className="card-text"
                                >
                                  {el.title}
                                </div>
                              }
                            />
                          </ListItem>
                        </Link>
                      );
                    })}
                </div>
              </CardContent>
            </Card>

            <Dialog onClose={() => setOpen(el => !el)} aria-labelledby="dialog-title" open={open}>
              <DialogTitle
                disableTypography
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
                id="dialog-title"
              >
                <div>
                  <b>Aktuális jelentési kötelezettségeim</b>
                </div>
                <IconButton aria-label="close" onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              {userData &&
                JSON.parse(userData.view.content).map(el => {
                  let bundle = JSON.parse(el.tender_info).bundle;

                  let endDate =
                    new Date(el.field_monthly_report_end_date).toISOString().replaceAll("-", ".").split("T")[0] +
                    ".-án/én, " +
                    new Date(el.field_monthly_report_end_date).toTimeString().split(" ")[0] +
                    "-kor lejár.";

                  return (
                    <Link
                      className="card-link"
                      to={{
                        pathname: "/jelentes",
                        state: {
                          id: el.nid,
                          bundle,
                        },
                      }}
                    >
                      <ListItem button>
                        <ListItemText primary={el.title} secondary={endDate} />
                      </ListItem>
                    </Link>
                  );
                })}
            </Dialog>
          </>
        )}

        <Link to="profil" className="card-link">
          <Card className="card center">
            <CardContent>
              <span className="card-image homeuser"></span>
              <h2 className="card-underline">Profilom</h2>
              <div className="card-text">Ide kattintva tudod szerkeszteni a profilod</div>
            </CardContent>
          </Card>
        </Link>

        {contract && !isKelep && showContract.length > 0 && (
          <>
            <Card className="card center" style={{ cursor: "pointer" }} onClick={() => setOpen(el => !el)}>
              <CardContent style={{ paddingBottom: "0" }}>
                <span className="card-image homepalyazatok"></span>
                <h2 className="card-underline">Szerződéskötés</h2>
                <div className="card-text">
                  {showContract.map((row, idx) => (
                    <ListItem
                      button
                      style={{
                        padding: "5px 0px",
                        borderRadius: "8px",
                      }}
                    >
                      <ListItemText
                        to
                        primary={
                          <div
                            style={{
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                            className="card-text"
                          >
                            <Link
                              to={{
                                pathname: "/szerkesztes",
                                state: {
                                  id: row.nid[0].value,
                                },
                              }}
                            >
                              {row.title[0]?.value}
                            </Link>
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </div>
      {reportAlert && !isKelep && (
        <div className="report">
          <h2 style={{ margin: 0 }}>Figyelmeztetés!</h2>
          <p>
            A {title} havi jelentési határideje {report}-kor lejár.
          </p>
        </div>
      )}
    </div>
  );
};
